import httpAxios from '@/utils/http-axios.js'
import { mapGetters } from 'vuex'
import currentRouteMixin from '@/mixins/current-route.js'

export default {
	name: 'SidePanel',
	data() {
		return {
			appName: process.env.VUE_APP_NAME
		}
	},
	mixins: [currentRouteMixin],
	computed: {
		...mapGetters({
			loggedUser: 'getLoggedUser'
		})
	},
	methods: {
		logOut: function () {
			httpAxios({
				url: this.$backendUrl + '/logout',
				method: 'POST'
			})

			this.$store.dispatch('logOut')
			this.$router.go({ name: 'login' })
		}
	}
}