import httpAxios from '@/utils/http-axios.js'
import { mapGetters } from 'vuex'

export default {
    name: 'Header',
    computed: {
		...mapGetters({
			loggedUser: 'getLoggedUser'
		})
	},
    methods: {
		logOut: function () {
			httpAxios({
				url: this.$backendUrl + '/logout',
				method: 'POST'
			})

			this.$store.dispatch('logOut')
			this.$router.go({ name: 'login' })
		}
	}
}